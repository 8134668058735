import React from "react";
import { Tooltip, Typography } from "antd";
import { InfoIcon } from "assets/icons/DetailedProfile";
import "./components.scss";
interface PropsType {
  candidate: any;
  match: any;
  showBenchmark?: any;
}

interface BenchmarkProps {
  title: any;
  isActive: boolean;
}

const Styles = {
  root: {
    padding: "12.5px 12.5px 0 12.5px",
    borderRadius: 10,
    backgroundColor: "white",
    // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
    // paddingBottom: 15,
    marginRight: "35px",
    marginTop: "-28px",
  },
  border: {
    border: "solid 1px #012653",
    borderRadius: 10,
    padding: 5,
  },
  printProfile: {
    with: "275px",
  },
  benchmark: {
    width: 230,
    height: 37,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #012653",
    borderRadius: 10,
    marginBottom: 10,
  },
  benchmarkActive: {
    width: 230,
    height: 37,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #012653",
    borderRadius: 10,
    marginBottom: 10,
    color: "white",
    backgroundColor: "#2E4A79",
  },
};

const benchMarks = ["Plug & play", "Best athlete", "Step up"];

const BenchmarkInfo = () => {
  return (
    <div>
      <p>Definitions for benchmark</p>

      <p>
        Plug & Play: A candidate in a company from a similar/related industry
        with a similar business model considered to be ready to take on this
        role (can hit the ground running).
      </p>

      <p>
        Best Athlete: A candidate in a company from a different industry with a
        different business model but has experience leading a similar size
        responsibility.
      </p>

      <p>
        Step up: A candidate in a company from either a similar/related or
        different industry for which the role would be a next career step.
      </p>
    </div>
  );
};

const Benchmark: React.FC<PropsType> = ({
  candidate,
  match,
  showBenchmark = true,
}) => {
  const BenchMarkBtn = ({ title, isActive }: BenchmarkProps) => {
    return (
      <div style={isActive ? Styles.benchmarkActive : Styles.benchmark}>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 500,
            color: isActive ? "white" : "#2E4A79",
          }}
        >
          {title}
        </Typography>
      </div>
    );
  };

  return (
    <>
      {showBenchmark === true ? (
        <div style={{ ...Styles.root, right: 0, position: "absolute" }}>
          <div style={Styles.border} className="shadow-bench">
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Tooltip
                className="color-benchMark"
                overlayStyle={{ width: 300 }}
                arrowPointAtCenter={false}
                overlayInnerStyle={{ width: 300, borderRadius: "10px" }}
                placement="bottomRight"
                title={<BenchmarkInfo />}
              >
                <img src={InfoIcon} />
              </Tooltip>
            </div>
            <Typography
              style={{
                textAlign: "center",
                fontSize: 16,
                // marginBottom: 15,
                fontWeight: "600",
                color: "#012653",
              }}
            >
              BENCHMARK
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0.5em",
              }}
            >
              {benchMarks.map((benchMark: any, index: any) => (
                <BenchMarkBtn
                  key={index}
                  title={benchMark}
                  isActive={match?.benchmark == benchMark}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Benchmark;
