import { Button, Card, Image, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import linkedIn from "../../../../assets/icons/social/linkedIn.svg";
import facebook from "../../../../assets/icons/social/Facebook.svg";
import github from "../../../../assets/icons/social/Github.svg";
import twitter from "../../../../assets/icons/social/Twitter.svg";
import editPencil from "../../../../assets/icons/profileIcons/editPencil.svg";

interface ProfileCardProps {
  recruiter: any;
  setDisplayGeneralDrawer: any;
  isEditing: boolean;
  data: any;
}

const ProfileCard = ({
  recruiter,
  setDisplayGeneralDrawer,
  isEditing,
  data,
}: ProfileCardProps) => {
  const [upload, setUpload] = useState({
    loading: false,
    url: recruiter?.profilePic
      ? recruiter?.profilePic
      : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255532-stock-illustration-profile-placeholder-male-default-profile.jpg",
    error: "",
  });

  const { Title, Paragraph } = Typography;

  const handleNavigation = (link: any) => {
    window.open(link, "_blank");
  };

  const socialProfileLinks = [
    { Icon: linkedIn, src: data?.linkedIn },
    { Icon: github, src: data?.github },
    // { Icon: twitter, src: data?.twitter },
    { Icon: facebook, src: data?.facebook },
  ];

  useEffect(() => {
    setUpload({
      loading: false,
      url: data?.profilePic
        ? data?.profilePic
        : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255532-stock-illustration-profile-placeholder-male-default-profile.jpg",
      error: "",
    });
  }, [data?.profilePic]);

  return (
    <Card>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginTop: "-6px",
          marginRight: "-6px",
        }}
      >
        {isEditing && (
          <Tooltip title="edit" style={{}}>
            <Button
              style={{
                zIndex: "5",
                position: "relative",
                backgroundColor: "#1A838C",
                width: "26px",
                minWidth: "26px",
                height: "26px",
              }}
              className="border-primary"
              shape="circle"
              icon={
                <img
                  style={{
                    height: "11.5px",
                    width: "11.5px",
                  }}
                  src={editPencil}
                  alt="editPencil"
                />
              }
              onClick={() => setDisplayGeneralDrawer(true)}
            />
          </Tooltip>
        )}
      </div>
      <div
        style={{
          paddingBottom: "8px",
          marginTop: !isEditing ? "26px" : "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <Image
              className="user-profile-avatar"
              src={upload.url}
              style={{
                zIndex: "5",
                borderRadius: "50%",
                height: "64px",
                width: "64px",
                border: "8px solid white",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
        <Title
          className="m-t-10 user-avatar-title mb-0"
          level={5}
          style={{ color: "#012653", fontSize: 18 }}
        >
          {/* {data?.firstName && data?.lastName
            ? data?.firstName + " " + data?.lastName
            : data?.fullName} */}
          {data?.fullName}
        </Title>
        <Paragraph
          className=" text-center mb-0"
          style={{ fontWeight: "500", fontSize: 16 }}
        >
          {data?.title}
        </Paragraph>
        {data?.company && (
          <Paragraph
            className=" text-center mb-0"
            style={{ fontWeight: "600", fontSize: 14 }}
          >
            @{data?.company}{" "}
            <span style={{ fontWeight: "500" }}>
              {data?.location?.city && (
                <>
                  <span style={{ fontWeight: "600" }}>| </span>
                  {data?.location?.city}
                </>
              )}
              {data?.location?.country && <>, {data?.location?.country}</>}
            </span>
          </Paragraph>
        )}
        <Paragraph
          className="text-center mb-0 "
          style={{
            color: "#012653",
            fontWeight: 500,
            fontSize: 16,
          }}
        >
          {data?.user && data?.user?.email}
        </Paragraph>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {socialProfileLinks.map((link: any) => {
            return link.src?.length > 3 ? (
              <span
                className="mt-2"
                onClick={() => handleNavigation(link.src)}
                style={{ cursor: "pointer" }}
              >
                {" "}
                <img src={link.Icon} alt={link.src} />
              </span>
            ) : null;
          })}
        </div>
      </div>
    </Card>
  );
};

export default ProfileCard;
