import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";

import {
  Row,
  Col,
  Tabs,
  Card,
  Table,
  Button,
  Space,
  Tooltip,
  Popconfirm,
  Tag,
  Skeleton,
  notification,
  Popover,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useRouteMatch, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { Fade } from "react-awesome-reveal";
import ErrorCard from "../../../components/UI/ErrorCard/ErrorCard";
import { ColumnsType } from "antd/es/table";
import {
  useUpdateJobStatusMutation,
  useDeleteJobMutation,
  useUpdateJobIsFavouriteMutation,
  useUserAllJobsLazyQuery,
} from "../../../graphql/generated/graphql";
import { useDispatch, useSelector } from "react-redux";
import { DeleteJob, StoreJob, StoreJobs } from "../../../store/jobs/actions";
import moment from "moment";
import { JobsState } from "store/jobs/types";
import Buttons from "components/UI/Buttons";
import PopoverText from "helpers/PopOver";
import JobSearchbar from "./JobListingSearch";
import "./JobListings.scss";
const { Column, ColumnGroup } = Table;
const { TabPane } = Tabs;

const JobListings: React.FC = () => {
  //userId is here to favouriteBy

  const stringifyUserObject: any = localStorage.getItem("user");
  const user = JSON.parse(stringifyUserObject);
  let history = useHistory();
  const [archiveVisible, setArchiveVisible] = React.useState(false);
  const [deleteVisible, setDeleteVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [hasError, setHasError] = useState(false);
  const [activeJobs, setActiveJobs]: any = useState([]);
  const [archivedJobs, setArchivedJobs]: any = useState([]);
  const [isSearchFieldEmpty, setIsSearchFieldEmpty]: any = useState(false);
  const [searchOptions, setSearchOptions]: any = useState({
    searchText: "",
    searchField: "All",
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    // total: 0,
  });
  const [sorting, setSorting] = useState({
    column: "favouriteBy",
    order: "descend",
  });
  const [jobsCount, setJobsCount] = useState(0);
  const dispatch = useDispatch();
  const { url, path } = useRouteMatch();
  const [loadJobsForSearch, { data, loading, error }]: any =
    useUserAllJobsLazyQuery({
      variables: {
        userId: user?.userId,
        searchText: "",
        searchField: "All",
        page: 1,
        limit: 10,
        sortField: "",
        sortOrder: "",
      },
      fetchPolicy: "network-only",
    });
  useEffect(() => {
    loadJobsForSearch({
      variables: {
        userId: user?.userId,
        searchText: searchOptions?.searchText,
        searchField: searchOptions?.searchField || "All",
        page: pagination.current,
        limit: pagination.pageSize,
        sortField: sorting.column,
        sortOrder: sorting.order,
      },
    });
  }, [
    // searchOptions?.searchText,
    // searchOptions?.searchField,
    pagination?.current,
    pagination?.pageSize,
    sorting?.column,
    sorting?.order,
  ]);
  useEffect(() => {
    // setPagination({ current: 1, pageSize: 10 });
    // setSorting({ column: "favouriteBy", order: "descend" });
    // loadJobsForSearch({
    //   variables: {
    //     userId: user?.userId,
    //     searchText: searchOptions?.searchText,
    //     searchField: searchOptions?.searchField || "All",
    //     page: 1,
    //     limit: pagination.pageSize,
    //     sortField: "favouriteBy",
    //     sortOrder: "descend",
    //   },
    // });
  }, [searchOptions?.searchText, searchOptions?.searchField]);

  useEffect(() => {
    if (isSearchFieldEmpty) {
      loadJobsForSearch({
        variables: {
          userId: user?.userId,
          searchText: "",
          searchField: searchOptions?.searchField || "All",
        },
      });
    }
  }, [isSearchFieldEmpty]);

  useEffect(() => {
    setJobsCount(activeJobs[0]?.totalJobs || 0);
  }, [activeJobs]);

  const [updateJobIsFavouriteMutation] = useUpdateJobIsFavouriteMutation();

  const [updateJobStatusMutation, UpdateJobStatus] =
    useUpdateJobStatusMutation();
  const [deleteJobStatusMutation, deleteJobStatus] = useDeleteJobMutation();
  const jobs = useSelector((state: JobsState) => state.jobs?.jobs);
  const handleSearch = (params: any) => {
    setSearchOptions(params);
  };

  const handleClick = (searchValues: any) => {
    setPagination({ current: 1, pageSize: 10 });
    setSorting({ column: "favouriteBy", order: "descend" });
    loadJobsForSearch({
      variables: {
        userId: user?.userId,
        searchText: searchValues?.searchText,
        searchField: searchValues?.searchField || "All",
        page: 1,
        limit: pagination.pageSize,
        sortField: "favouriteBy",
        sortOrder: "descend",
      },
    });
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // if (sorter.field === "link") {
    //   sorter.field = "title";
    // }
    // if (sorter.field === "_id") {
    //   sorter.field = "favouriteBy";
    // }
    // if (sorter.field === "company") {
    //   sorter.field = "companyName";
    // }
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
    setSorting({
      column: sorter.field,
      order: sorter.order,
    });
  };

  const showHideArchivePopconfirm = (index: any, value: any) => {
    const temp = [...activeJobs];
    temp[index].popupVisible = value;
    setActiveJobs(temp);
  };

  const showHideActivePopconfirm = (index: any, value: any) => {
    const temp = [...archivedJobs];
    temp[index].popupVisible = value;
    setArchivedJobs(temp);
  };

  const showHideActiveDeletePopconfirm = (index: any, value: any) => {
    const temp = [...activeJobs];
    temp[index].deletePopupVisible = value;
    setActiveJobs(temp);
  };

  const showHideArchiveDeletePopconfirm = (index: any, value: any) => {
    const temp = [...archivedJobs];
    temp[index].deletePopupVisible = value;
    setArchivedJobs(temp);
  };

  // const showDeletePopconfirm = () => {
  //   setDeleteVisible(true);
  // };

  const updateJobStatusHandler = (jobId: any, status: string, index: any) => {
    setConfirmLoading(true);

    updateJobStatusMutation({
      variables: {
        jobId: jobId,
        status: status,
      },
    })
      .then(({ data }) => {
        dispatch(StoreJob({ ...data?.updateJobStatus }));
        notification["success"]({
          message: "Job status updated successfully.",
          duration: 1.5,
          // onClose: () =>
          //   history.push(`/job-listings/edit/${jobId}`),
          description: `Your Job has been ${status}`,
        });
        setConfirmLoading(false);
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification["error"]({
          message: "Unable to create job",
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  const deleteJobStatusHandler = (jobId: any, status: string, index: any) => {
    setConfirmLoading(true);

    deleteJobStatusMutation({
      variables: {
        jobId: jobId,
      },
    })
      .then(({ data }) => {
        dispatch(DeleteJob(jobId));
        notification["success"]({
          message: "Job status updated successfully.",
          // onClose: () =>
          //   history.push(`/job-listings/edit/${jobId}`),
          description: `Your Job has been deleted.`,
        });
        setDeleteVisible(false);
        setConfirmLoading(false);
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification["error"]({
          message: "Unable to create job",
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  const handleArchiveCancel = (index: any) => {
    console.log("Clicked cancel button");
    setArchiveVisible(false);
  };

  const handleDeleteCancel = () => {
    console.log("Clicked cancel button");
    setDeleteVisible(false);
  };

  useEffect(() => {
    if (jobs) {
      // console.log("Jobs ==> ", jobs);
      let activeJobsList = jobs
        .filter((job: any) => job.status == "active" || job?.status == "draft")
        .map((j: any) => {
          return {
            ...j,
            popupVisible: false,
            deletePopupVisible: false,
          };
        });
      // activeJobsList = activeJobsList?.sort((a: any, b: any) =>
      // a?.favouriteBy?.includes(user?.userId) ? -1 : 1
      // );
      const archivedJobsList = jobs
        .filter((job: any) => job.status == "archive")
        .map((j: any) => {
          return {
            ...j,
            popupVisible: false,
            deletePopupVisible: false,
          };
        });
      setActiveJobs(activeJobsList);
      setArchivedJobs(archivedJobsList);

      // console.log("Active : ", activeJobsList);
      // console.log("Archive: ", archivedJobsList);
    }
  }, [jobs]);

  useEffect(() => {
    if (!loading && data && data.getUserJobs) {
      dispatch(StoreJobs(data.getUserJobs));
    }
  }, [data]);

  // console.log(archivedJobs);
  const isFavouriteJob = (jobId: string) => {
    const isFav = jobs?.find((job: any) => job._id === jobId);
    return isFav && isFav?.favouriteBy?.includes(user?.userId);
  };
  const handleToggleFavouriteJob = (jobId: string) => {
    setConfirmLoading(true);
    updateJobIsFavouriteMutation({
      variables: {
        jobId: jobId,
        isFavourite: !isFavouriteJob(jobId),
      },
    })
      .then(({ data }) => {
        dispatch(StoreJob({ ...data?.updateJobIsFavourite })); //update favourite job.....

        const allJobs = activeJobs;
        const index = allJobs.findIndex(
          (job: any) => job._id === data?.updateJobIsFavourite?._id
        );
        allJobs[index].favouriteBy = data?.updateJobIsFavourite?.favouriteBy;

        allJobs.forEach((job: any) => {
          if (job._id == jobId) {
          }
        });

        setActiveJobs([
          ...allJobs.filter((job: any) => job?.favouriteBy?.length > 0),
          ...allJobs.filter((job: any) => job?.favouriteBy?.length == 0),
        ]);

        setActiveJobs([
          ...activeJobs.sort((a: any, b: any) =>
            a?.favouriteBy?.includes(user?.userId) ? -1 : 1
          ),
        ]);

        notification["success"]({
          message: "Job updated successfully.",
          // description: `udpated isFavourite`,
        });
        setConfirmLoading(false);
      })
      .catch((err) => {
        const errors = err?.graphQLErrors?.map((el: any) => el.message);
        notification["error"]({
          message: "Unable to update job",
          description: errors?.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };
  const actionsHandler = (_id: any) => {
    return (
      <Space>
        <Buttons
          toolTipTitle={isFavouriteJob(_id) ? "Deprioritize" : "Prioritize"}
          type={isFavouriteJob(_id) ? "btn-favourite" : "btn-unfavourite"}
          onClick={() => handleToggleFavouriteJob(_id)}
        />
      </Space>
    );
  };
  const RecruiterContent = (RecArr: any) => {
    console.log(RecArr);
    return (
      <>
        {RecArr?.recruitersDetail?.map((R: any, index: any) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", marginTop: 10 }}
          >
            <img
              src={
                R?.profilePic
                  ? R?.profilePic
                  : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255532-stock-illustration-profile-placeholder-male-default-profile.jpg"
              }
              alt={R?.fullName}
              className="rounded-circle-cmp thumb-md img-thumbnail-rec"
            />
            <div style={{ marginLeft: "10px" }}>
              <p
                style={{
                  margin: "0",
                  color: "#012653",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {R?.fullName}
              </p>
              <p
                style={{
                  margin: "0",
                  color: "#495057",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {R?.title}
              </p>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <PageTitle
        back
        backURL="/companies"
        title="List of jobs"
        btnText="Add job"
        to={`${path}/add-job`}
        btnActive={false}
      />
      <JobSearchbar
        handleSearch={handleSearch}
        handleClick={handleClick}
        setIsSearchFieldEmpty={setIsSearchFieldEmpty}
      />
      <Row justify="center" className="pb-4" gutter={16}>
        <Col span={24}>
          <Fade>
            {/* className="p-24" */}
            <Card>
              {loading && !jobs ? (
                <Skeleton />
              ) : (
                <Tabs defaultActiveKey="1" className="tab-color">
                  <TabPane
                    tab={
                      <span style={{ fontSize: 16 }}>
                        Number of jobs
                        <span
                          className="tab-pane-count"
                          style={{ marginLeft: "20px" }}
                        >
                          {jobsCount}
                        </span>
                      </span>
                    }
                    key="1"
                  >
                    <Table
                      dataSource={activeJobs}
                      size="small"
                      className="table-striped"
                      scroll={{ x: 1200 }}
                      onChange={handleTableChange}
                      loading={loading}
                      pagination={{
                        total: jobsCount,
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                      }}
                    >
                      <Column
                        title="Title"
                        dataIndex="title"
                        key="title"
                        width="280px"
                        sortDirections={["ascend", "descend", "ascend"]}
                        // defaultSortOrder="ascend"
                        // sorter={(a: any, b: any) => {
                        //   if (!a.title) {
                        //     a.title = "";
                        //   }
                        //   if (!b.title) {
                        //     b.title = "";
                        //   }
                        //   return a?.title?.localeCompare(b?.title);
                        // }}
                        sorter={true}
                        showSorterTooltip={false}
                        render={(title, record: any) => (
                          <div>
                            <Link to={`/job-listings/view/${record._id}`}>
                              <PopoverText
                                country={title}
                                isTag={false}
                                event={"hover"}
                                placement={"topLeft"}
                              />
                              {/* {title} */}
                            </Link>
                            {/* <Link to={`/companies/view/${record.company._id}`}>
                              <div className="companyConatiner">
                                <img
                                  className="rounded-circle thumb-sm img-thumbnail"
                                  // src="https://blonk-logos.s3.amazonaws.com/attachment-5471245431324071978Icon-76_U00402x%20%282%29.png"
                                  src={record.company.logoUrl}
                                />
                                <span className="pl-1 dotted-bottom-border">
                                  {record.company.name}
                                </span>
                              </div>
                            </Link> */}
                          </div>
                        )}
                      />
                      <Column
                        title="Company"
                        dataIndex="company"
                        key="company"
                        sortDirections={["ascend", "descend", "ascend"]}
                        // defaultSortOrder="descend"
                        // sorter={(a: any, b: any) => {
                        //   const companyA = a?.company?.name || "";
                        //   const companyB = b?.company?.name || "";

                        //   return companyA.localeCompare(companyB);
                        // }}
                        sorter={true}
                        showSorterTooltip={false}
                        width="280px"
                        render={(title, record: any) => (
                          <div>
                            {/* <Link to={`/job-listings/view/${record._id}`}>
                              {title}
                            </Link> */}
                            <Link to={`/companies/view/${record.company._id}`}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  className="rounded-circle thumb-sm img-thumbnail"
                                  // src="https://blonk-logos.s3.amazonaws.com/attachment-5471245431324071978Icon-76_U00402x%20%282%29.png"
                                  src={record.company.logoUrl}
                                />
                                <span
                                  className="pl-1"
                                  style={{
                                    maxWidth: "240px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <Popover
                                    placement="topLeft"
                                    title={""}
                                    content={record?.company?.name}
                                    trigger={"hover"}
                                    style={{ width: "100%" }}
                                    className="Popover-long-location"
                                  >
                                    {record.company.name}
                                  </Popover>
                                </span>
                              </div>
                            </Link>
                          </div>
                        )}
                      />
                      <Column
                        title="Location"
                        dataIndex="countries"
                        key="countries"
                        width="280px"
                        sortDirections={["ascend", "descend", "ascend"]}
                        // defaultSortOrder="descend"
                        // sorter={(a: any, b: any) => {
                        //   let aName = a?.countries?.map((c: any) => c.name);
                        //   aName = aName?.join(" ");
                        //   let bName = b?.countries?.map((c: any) => c.name);
                        //   aName = bName?.join(" ");
                        //   console.log(a?.countries);
                        //   return aName.localeCompare(bName);
                        // }}
                        // sorter={(a: any, b: any) => {
                        //   const flatA = a?.countries?.flat();
                        //   const flatB = b?.countries.flat();
                        //   console.log(a);
                        //   const nameA = flatA
                        //     .map((item: any) => item.name)
                        //     .sort()
                        //     .join("");
                        //   const nameB = flatB
                        //     .map((item: any) => item.name)
                        //     .sort()
                        //     .join("");

                        //   return nameA.localeCompare(nameB);
                        // }}
                        sorter={true}
                        showSorterTooltip={false}
                        render={(countries) => (
                          <PopoverText
                            countries={countries}
                            isTag={false}
                            isJobLocation={true}
                          />
                        )}
                      />
                      <Column
                        title="Recruiters"
                        dataIndex="recruiters"
                        key="recruiters"
                        width="150px"
                        render={(recruiters, recruiterInfo: any) => {
                          if (recruiterInfo?.recruitersDetail?.length > 0) {
                            return (
                              <>
                                <Popover
                                  content={() =>
                                    RecruiterContent(recruiterInfo)
                                  }
                                  showArrow={false}
                                  title=""
                                  trigger="click"
                                  placement="bottom"
                                >
                                  <Button
                                    style={{
                                      border: "none",
                                      width: 40,
                                      height: 40,
                                      padding: "unset",
                                      backgroundColor: "#BC9623",
                                      borderRadius: "50%",
                                      color: "#FFFFFF",
                                      outline: "none",
                                      boxShadow:
                                        "rgba(0, 0, 0, 0.25) 0px 4px 4px",
                                    }}
                                  >
                                    {" "}
                                    {recruiters?.length}{" "}
                                  </Button>
                                </Popover>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <Button
                                  style={{
                                    border: "none",
                                    width: 40,
                                    height: 40,
                                    padding: "unset",
                                    backgroundColor: "#BC9623",
                                    borderRadius: "50%",
                                    color: "#FFFFFF",
                                    outline: "none",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.25) 0px 4px 4px",
                                  }}
                                >
                                  {" "}
                                  {recruiters?.length}{" "}
                                </Button>
                              </>
                            );
                          }
                        }}
                      />
                      {/* <Column
                        title="Confidential"
                        dataIndex="isConfidential"
                        key="Confidential"
                        render={(isConfidential) => (
                          <>
                            <Tag color="blue">
                              {isConfidential ? "Yes" : "No"}
                            </Tag>
                          </>
                        )}
                      /> */}
                      {/* <Column
                        title="Interview dashboard"
                        dataIndex="_id"
                        key="_id"
                        render={(_id) => (
                          <>
                            <Link to={`${url}/dashboard/${_id}`}>
                              <Button className="ant-btn-outline-primary">
                                Interview dashboard
                              </Button>
                            </Link>
                          </>
                        )}
                      /> */}
                      <Column
                        title="New candidates"
                        dataIndex="_id"
                        key="candidates"
                        width="200px"
                        render={(_id, row: any) => {
                          let newCandidate = row?.candidates?.filter(
                            (CD: any) => CD?.recruiterAction == null
                          )?.length;

                          return (
                            <>
                              <Button
                                className="ant-btn-teal"
                                style={{
                                  width: 40,
                                  height: 40,
                                  borderRadius: 2,
                                  boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px",
                                }}
                                onClick={() =>
                                  history.push({
                                    pathname: `/candidate-listings/${_id}`,
                                    state: { fromURL: "/job-listings" },
                                  })
                                }
                              >
                                {/* {candidates} Candidates */}
                                {row?.candidates ? newCandidate : 0}{" "}
                              </Button>
                            </>
                          );
                        }}
                      />
                      {/* <Column
                        title="Crawlies"
                        dataIndex="crawlies"
                        key="crawlies"
                        render={(crawlies) => (
                          <>
                            <Link to="#">
                              <Button className="ant-btn-burgundy">
                                {'10'} Crawlies
                              </Button>
                            </Link>
                          </>
                        )}
                      /> */}
                      <Column
                        title="Created on"
                        dataIndex="createdAt"
                        key="createdAt"
                        width="150px"
                        sortDirections={["descend", "ascend", "descend"]}
                        // defaultSortOrder="descend"
                        // sorter={(a: any, b: any) => {
                        //   if (!a.createdAt) a.createdAt = "";
                        //   if (!b.createdAt) b.createdAt = "";
                        //   return a.createdAt - b.createdAt;
                        // }}
                        sorter={true}
                        showSorterTooltip={false}
                        render={(createdAt, record: any) => (
                          <>{moment(parseInt(createdAt)).format("DD/MM/YY")}</>
                        )}
                      />
                      <Column
                        title="Status"
                        dataIndex="status"
                        key="status"
                        width="150px"
                        sortDirections={["ascend", "descend", "ascend"]}
                        // defaultSortOrder="descend"
                        // sorter={(a: any, b: any) =>
                        //   a?.status?.localeCompare(b?.status)
                        // }
                        sorter={true}
                        showSorterTooltip={false}
                        render={(status, record: any) => (
                          // <>{moment(parseInt(createdAt)).format("DD/MM/YY")}</>
                          <p className="mt-3">
                            {status == "draft" ? <>Draft</> : <>Posted</>}
                          </p>
                        )}
                      />
                      <Column
                        title="Priority"
                        dataIndex="_id"
                        key="_id"
                        width="100px"
                        fixed="right"
                        showSorterTooltip={false}
                        // sortDirections={["descend", "ascend", "descend"]}
                        // defaultSortOrder="descend"
                        sorter={(a: any, b: any) => {
                          const isFavouriteA = a?.favouriteBy?.includes(
                            user?.userId
                          );
                          const isFavouriteB = b?.favouriteBy?.includes(
                            user?.userId
                          );

                          // Sort based on whether the item is a favourite or not
                          if (isFavouriteA && !isFavouriteB) return -1;
                          if (!isFavouriteA && isFavouriteB) return 1;
                          return 0; // Keep original order if both are favourites or non-favourites
                        }}
                        // sorter={(a: any, b: any) =>
                        //   a?.favouriteBy?.includes(user?.userId) ? -1 : 1
                        // }
                        // sorter={true}
                        // render={(_id, row: any, index) => (
                        //   <>
                        //     <Space>
                        //       {/* <Tooltip title="edit">
                        //         <Button
                        //           className="border-primary"
                        //           shape="circle"
                        //           onClick={() =>
                        //             history.push(`/job-listings/edit/${_id}`)
                        //           }
                        //           icon={
                        //             <EditOutlined className="text-primary" />
                        //           }
                        //         />
                        //       </Tooltip> */}
                        //       {/* <Tooltip title="edit"> */}
                        //       <Buttons
                        //         toolTipTitle="favourite"
                        //         type="btn-favourite"
                        //         onClick={() => { }}

                        //       />
                        //       {/* <Button
                        //           className="border-primary"
                        //           shape="circle"
                        //           onClick={() =>
                        //             history.push(`/job-listings/edit/${_id}`)
                        //           }
                        //           icon={
                        //             <EditOutlined className="text-primary" />
                        //           }
                        //         /> */}
                        //       {/* </Tooltip> */}

                        //       {/* <Tooltip title="archive">
                        //         <Popconfirm
                        //           title="Archive this job. Please confirm"
                        //           okText="Yes"
                        //           cancelText="No"
                        //           visible={row.popupVisible}
                        //           onConfirm={() =>
                        //             updateJobStatusHandler(
                        //               _id,
                        //               "archive",
                        //               index
                        //             )
                        //           }
                        //           okButtonProps={{ loading: confirmLoading }}
                        //           onCancel={() =>
                        //             showHideArchivePopconfirm(index, false)
                        //           }
                        //         >
                        //           <Button
                        //             className="border-amber"
                        //             shape="circle"
                        //             onClick={() =>
                        //               showHideArchivePopconfirm(index, true)
                        //             }
                        //           >
                        //             <i className="dripicons-archive text-amber" />
                        //           </Button>
                        //         </Popconfirm>
                        //       </Tooltip> */}
                        //       {/* <Tooltip title="delete">
                        //         <Popconfirm
                        //           title="Confirm you want to delete this job"
                        //           okText="Yes"
                        //           cancelText="No"
                        //           onConfirm={() =>
                        //             deleteJobStatusHandler(_id, "delete", index)
                        //           }
                        //           okButtonProps={{ loading: confirmLoading }}
                        //           onCancel={() =>
                        //             showHideActiveDeletePopconfirm(index, false)
                        //           }
                        //         >
                        //           <Button
                        //             className="border-burgundy"
                        //             shape="circle"
                        //             icon={
                        //               <DeleteOutlined className="text-burgundy" />
                        //             }
                        //           />
                        //         </Popconfirm>
                        //       </Tooltip> */}
                        //     </Space>
                        //   </>
                        // )}
                        render={actionsHandler}
                      />
                    </Table>
                  </TabPane>
                  {/* <TabPane
                    tab={
                      <span>
                        Archived jobs
                        <span className="tab-pane-count">
                          {archivedJobs.length}
                        </span>
                      </span>
                    }
                    key="2"
                  >
                    <Table dataSource={archivedJobs} size="small">
                      <Column
                        title="Date"
                        dataIndex="createdAt"
                        key="createdAt"
                        render={(createdAt, record: any) => (
                          <>
                            {moment(parseInt(createdAt)).format(
                              "MMMM DD, YYYY"
                            )}
                          </>
                        )}
                      />
                      <Column
                        title="Title"
                        dataIndex="title"
                        key="title"
                        render={(title, record: any) => (
                          <div>
                            <Link to={`/job-listings/view/${record._id}`}>
                              {title}
                            </Link>
                            <Link to={`/companies/view/${record.company._id}`}>
                              <div className="companyConatiner">
                                <img
                                  className="rounded-circle thumb-sm img-thumbnail"
                                  // src="https://blonk-logos.s3.amazonaws.com/attachment-5471245431324071978Icon-76_U00402x%20%282%29.png"
                                  src={record.company.logoUrl}
                                />
                                <span className="pl-1 dotted-bottom-border">
                                  {record.company.name}
                                </span>
                              </div>
                            </Link>
                          </div>
                        )}
                      />
                      <Column
                        title="Locations"
                        dataIndex="countries"
                        key="countries"
                        render={(countries) => (
                          <>
                            {countries.map((location: any) => (
                              <Tag color="blue" key={location._id}>
                                {location.name}
                              </Tag>
                            ))}
                          </>
                        )}
                      />
                      <Column
                        title="Interview dashboard"
                        dataIndex="_id"
                        key="_id"
                        render={(_id) => (
                          <>
                            <Link to={`${url}/dashboard/${_id}`}>
                              <Button className="ant-btn-outline-primary">
                                Interview dashboard
                              </Button>
                            </Link>
                          </>
                        )}
                      />
                      <Column
                        title="Candidates"
                        dataIndex="candidates"
                        key="candidates"
                        render={(candidates) => (
                          <>
                            <Link to="#">
                              <Button className="ant-btn-teal" style={{paddingRight:25}}>
                                {candidates.length} Candidates
                              </Button>
                            </Link>
                          </>
                        )}
                      />
                      <Column
                        title="Crawlies"
                        dataIndex="crawlies"
                        key="crawlies"
                        render={(crawlies) => (
                          <>
                            <Link to="#">
                              <Button className="ant-btn-burgundy">
                                {"10"} Crawlies
                              </Button>
                            </Link>
                          </>
                        )}
                      />
                      <Column
                        title="Actions"
                        dataIndex="_id"
                        align="center"
                        key="_id"
                        render={(_id, row: any, index) => (
                          <>
                            <Tooltip title="Active job">
                              <Popconfirm
                                title="Are you sure activate this job?"
                                okText="Yes"
                                cancelText="No"
                                visible={row.popupVisible}
                                onConfirm={() =>
                                  updateJobStatusHandler(_id, "active", index)
                                }
                                okButtonProps={{ loading: confirmLoading }}
                                onCancel={() =>
                                  showHideActivePopconfirm(index, false)
                                }
                              >
                                <Button
                                  className="border-amber"
                                  shape="circle"
                                  onClick={() =>
                                    showHideActivePopconfirm(index, true)
                                  }
                                >
                                  <i className="dripicons-clockwise text-amber" />
                                </Button>
                              </Popconfirm>
                            </Tooltip>
                          </>
                        )}
                      />
                    </Table>
                  </TabPane> */}
                  {/* <TabPane
                    tab={
                      <span>
                        Pending for approval
                        <span className="tab-pane-count">10</span>
                      </span>
                    }
                    key="3"
                  >
                    <p>No Data</p>
                  </TabPane>
                  <TabPane
                    tab={
                      <span>
                        Rejected jobs
                        <span className="tab-pane-count">8</span>
                      </span>
                    }
                    key="4"
                  >
                    <p>No Data</p>
                  </TabPane> */}
                </Tabs>
              )}
            </Card>
          </Fade>
        </Col>
      </Row>
    </>
  );
};

export default JobListings;
