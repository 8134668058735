import React, { useContext, useEffect, useState } from "react";
import { Drawer, Input, Form, Button, Col, Row, Select } from "antd";
import ProfilePicture from "./ProfilePicture";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Option } from "antd/lib/mentions";
import { EditContext } from "containers/Candidates/Profile/Context/EditContext";
interface Props {
  show: boolean;
  hide: any;
  loading: any;
  recruiter: any;
  data: any;
  setData: any;
}

const GeneralFormDrawer: React.FC<Props> = ({
  show,
  hide,
  loading,
  recruiter,
  data,
  setData,
}) => {
  const [form] = Form.useForm();
  const editContext = useContext(EditContext);

  const [isDisabled, setIsDisabled] = useState(true);
  const [uploadPicture, setUploadPicture] = useState<any>("");
  const [localData, setLocalData] = useState(data);
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: "AIzaSyACXA9qapZNvq51WpF69eIH908sX_efNMs",
      options: {
        types: ["geocode"],
      },
    });

  useEffect(() => {
    setIsDisabled(true);
    form.resetFields();
  }, [recruiter?.coverPhoto, show]);
  const handleChange = (e: any) => {
    const { name, value } = e.target; // Destructure to get name and value from e.target

    if (name) {
      setIsDisabled(false);

      setLocalData((prevLocalData: any) => {
        return { ...prevLocalData, [name]: value }; // Use captured name and value
      });
    } else {
      console.error("e.target.name is undefined or null");
    }
  };

  const validateUrl = (_: any, value: string) => {
    if (value && !value.startsWith("https://")) {
      return Promise.reject('URL must start with "https://"');
    }
    return Promise.resolve();
  };

  const handleSubmit = () => {
    setData((prevData: any) => {
      return { ...prevData, ...localData }; // Use captured name and value
    });
    hide();
  };

  useEffect(() => {
    setUploadPicture(recruiter?.profilePic);
  }, [recruiter?.profilePic]);

  useEffect(() => {
    setData((s: any) => ({ ...s, profilePic: uploadPicture }));
  }, [uploadPicture]);

  return (
    <>
      <Drawer
        title="Recruiter's General Info"
        placement="right"
        closable={true}
        onClose={() => {
          form.resetFields();
          hide();
        }}
        visible={show}
        width={500}
        className="menu-drawer"
      >
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          justify="center"
          className="pt-4 m-0"
        >
          <Col md={22} className="p-0">
            <p style={{ fontSize: "20px", fontWeight: "500", margin: "0px" }}>
              General information
            </p>
            <hr style={{ marginLeft: "-40px", width: "570px" }} />
            <Form layout="vertical" name="recruiter-general-info" form={form}>
              <Form.Item
                style={{ marginTop: "10px" }}
                label="My profile picture"
                initialValue={data?.profilePic}
                name="profilePicture"
                rules={[
                  {
                    required: true,
                    message: "Upload the profile photo",
                  },
                ]}
              >
                {recruiter && (
                  <>
                    <ProfilePicture
                      uploadPicture={uploadPicture}
                      setUploadPicture={setUploadPicture}
                      recruiter={recruiter}
                      form={form}
                      setIsDisabled={setIsDisabled}
                    />
                  </>
                )}
              </Form.Item>

              <Form.Item
                label="Full name"
                name="fullName"
                initialValue={data?.fullName}
                rules={[
                  {
                    required: true,
                    message: "Please input full name!",
                  },
                ]}
              >
                <Input onChange={handleChange} name="fullName" />
              </Form.Item>
              {/* <Form.Item
                label="Last name"
                name="lastName"
                initialValue={data?.fullName?.split(" ")[1]}
                rules={[
                  {
                    required: true,
                    message: "Please input last name!",
                  },
                ]}
              >
                <Input onChange={handleChange} name="lastName" />
              </Form.Item> */}
              <Form.Item
                label="Company name"
                name="company"
                initialValue={data?.company}
                rules={[
                  {
                    required: true,
                    message: "Please enter company name!",
                  },
                ]}
              >
                <Input onChange={handleChange} name="company" />
              </Form.Item>
              <Form.Item
                label="Title"
                name="title"
                initialValue={data?.title}
                rules={[
                  {
                    required: true,
                    message: "Please enter title!",
                  },
                ]}
              >
                <Input onChange={handleChange} name="title" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                initialValue={data?.user?.email}
                rules={[
                  {
                    required: true,
                    message: "Please enter your email!",
                  },
                ]}
              >
                <Input onChange={handleChange} name="email" />
              </Form.Item>
              <Form.Item
                name="location"
                label={"Location"}
                rules={[
                  {
                    required: true,
                    message: "Please select a location!",
                  },
                ]}
                className="myprofile-location"
                initialValue={
                  data?.location?.city + " " + data?.location?.country
                }
              >
                <Select
                  showSearch
                  placeholder={
                    <span style={{ fontSize: "14px" }}>
                      &nbsp; San-Francisco, USA
                    </span>
                  }
                  onSearch={(value: string) => {
                    getPlacePredictions({ input: value });
                  }}
                  onChange={(value) => {
                    setIsDisabled(false);
                    const city = value?.split(", ")[0];
                    const country = value?.split(", ")[1];
                    const location = { country, city };
                    setData((s: any) => ({ ...s, location }));
                    console.log(data);
                  }}
                  loading={isPlacePredictionsLoading}
                  filterOption={(input, option: any) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {placePredictions &&
                    placePredictions.map((company: any) => {
                      return (
                        <Option
                          key={company.description}
                          value={company.description}
                        >
                          {company.description}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label="LinkedIn"
                name="linkedin"
                initialValue={recruiter?.linkedIn}
                rules={[
                  {
                    required: false,
                    message: "Please input linkedin url!",
                  },
                  {
                    validator: validateUrl,
                  },
                ]}
              >
                <Input onChange={handleChange} name="linkedIn" />
              </Form.Item>
              <Form.Item
                label="Facebook"
                name="facebook"
                initialValue={recruiter?.facebook}
                rules={[
                  {
                    required: false,
                    message: "Please input facebook url!",
                  },
                  {
                    validator: validateUrl,
                  },
                ]}
              >
                <Input onChange={handleChange} name="facebook" />
              </Form.Item>
              {/* <Form.Item
                label="Twitter"
                name="twitter"
                initialValue={recruiter?.twitter}
                rules={[
                  {
                    required: false,
                    message: "Please input twitter url!",
                  },
                  {
                    validator: validateUrl,
                  },
                ]}
              >
                <Input onChange={handleChange} name="twitter" />
              </Form.Item> */}
              <Form.Item
                label="github"
                name="github"
                initialValue={recruiter?.github}
                rules={[
                  {
                    required: false,
                    message: "Please input github url!",
                  },
                  {
                    validator: validateUrl,
                  },
                ]}
              >
                <Input onChange={handleChange} name="github" />
              </Form.Item>

              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <p>
                    <span style={{ color: "#990000" }}>*</span> Mandatory fields
                  </p>
                  <Button
                    type="primary"
                    className="ant-btn-teal float-right px-5"
                    shape="round"
                    size="middle"
                    disabled={isDisabled}
                    loading={loading}
                    onClick={() => {
                      form
                        .validateFields()
                        .then(() => {
                          handleSubmit();
                        })
                        .catch((error) => {
                          console.error("Form validation failed", error);
                        });
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default GeneralFormDrawer;
