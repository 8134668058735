import React, { useEffect, useState, useRef } from "react";
import {
  Redirect,
  Route,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Row, Col, message, Button } from "antd";
import {
  useGetCandidateQuery,
  useGetMatchQuery,
  useGetSingleJobQuery,
} from "../../../graphql/generated/graphql";
import "./ViewCandidate.scss";
import {
  Attachment,
  Education,
  Experience,
  Languages,
  PersonalDetails,
  PersonalInfo,
  ProfessionalIntrests,
  Resume,
  TargetLocations,
  YearlyCompensation,
} from "../Profile";
import { Summary } from "../Profile/Summary/Index";
import { PageHeader, TopNavigation } from "../Profile/Shared";
import Comments from "../Profile/RecruiterComments/Comments";
import NextSteps from "../Profile/RecruiterComments/NextSteps";
import Myobjective from "../Profile/Motivations/Myobjective";
import Mytarget from "../Profile/Motivations/Mytarget";
import Inspirecompanies from "../Profile/Motivations/Inspirecompanies";
import Printbutton from "../Profile/Background/Printprops/Printbutton";
import AssessmentTable from "../Profile/RecruiterComments/AssessmentTable";
import ReactStickyNotes from "@react-latest-ui/react-sticky-notes";

interface ViewRecruiterProps {}

const ViewCandidate: React.FC<ViewRecruiterProps> = () => {
  const { path } = useRouteMatch();
  const { candidateId } = useParams<any>();
  const [candidates, setCandidates] = useState<any>(null);
  const location: any = useLocation();
  const professionalInterestsRef = useRef<HTMLDivElement>(null);
  const pdRef = useRef<HTMLDivElement>(null);
  const ycRef = useRef<HTMLDivElement>(null);
  const tfRef = useRef<HTMLDivElement>(null);
  // const printmatchId = location.state?.matchId
  const param: any = useParams();
  const {
    data: data1,
    loading: loading1,
    error: error1,
  }: any = useGetMatchQuery({
    variables: {
      matchId: location.state?.matchId,
    },
    fetchPolicy: "network-only",
  });

  const { data, loading, error } = useGetCandidateQuery({
    variables: {
      candidateId: candidateId,
    },
    fetchPolicy: "network-only",
  });
  const { data: jobData, loading: loadingJob }: any = useGetSingleJobQuery({
    variables: {
      jobId: location?.state?.jobId,
    },
    fetchPolicy: "network-only",
  });
  const [candidateData, setCandidateData] = useState<any | null | undefined>(
    null
  );

  const [matchData, setMatchData] = useState<any | null | undefined>(null);
  const [match, setMatch] = useState<any | null | undefined>(null);

  useEffect(() => {
    if (data1 && data1?.getMatch && data1?.getMatch?.candidateListing) {
      const { candidateListing, ...rest }: any = data1?.getMatch;
      setMatch(rest);
      setMatchData(rest);
      setCandidates(location.state?.candidateArray);
    }
  }, [data1]);

  useEffect(() => {
    if (data && data.getCandidate) {
      // if(data?.getCandidate?.education){
      //   data.getCandidate.educations = data.getCandidate?.education
      // }
      setCandidateData({
        ...data?.getCandidate,
        educations: data.getCandidate?.education,
      });
    }
  }, [data]);

  const UpdateChanges = () => {
    const key = "updatable";
    message.loading({ content: "Updating profile...", key });
  };

  let Motivation =
    (candidateData?.targetLocations?.length == 0 &&
      !candidateData?.personalInformation &&
      !candidateData?.yearlyCompensation?.currency &&
      candidateData?.inspiringCompanies?.length == 0 &&
      candidateData?.professionalInterests?.length == 0 &&
      candidateData?.objectives?.length == 0 &&
      candidateData?.sectors?.length == 0) == false;

  let Background =
    candidateData?.experiences?.length > 0 ||
    candidateData?.education?.length > 0 ||
    candidateData?.languages?.length > 0 ||
    candidateData?.resume ||
    candidateData?.coverLetter;

  return (
    <>
      {/* {     Notes feature disabled
        <div
          className="my-app-container"
          style={{ position: "relative", zIndex: 9999 }}
        >
          <style>
            {`
                                .rs-notes--navbar {
                                  // display: none !important;
                                }
                                  .rs-notes--navbar__item--button.rs-notes--navbar__item--button__pageview {
                          display: none;
                      }
                          .rs-notes--navbar__item--button.rs-notes--navbar__item--button__upload{
                              display: none;
                          }
                              `}
          </style>
          <ReactStickyNotes
            containerWidth="100%"
            // containerHeight="500px"
            notes={[{ color: "#BC9623", text: "Note 1" }]}
            noteWidth={200}
            noteHeight={150}
            onChange={(type, payload, notes) => {
              console.log("On Change:", type, payload, notes);
            }}
          />
        </div>
      } */}
      <div style={{ paddingTop: "10px" }}>
        <PageHeader handleSave={UpdateChanges} />
      </div>

      <div style={{ padding: 30, paddingTop: 4 }}>
        {/* Back, Edit , Save Cancel Button */}
        {location.state ? (
          <>
            {/* Main Body Content */}
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 10,
                paddingLeft: 35,
                paddingRight: 35,
                paddingTop: 29,
                paddingBottom: 29,
              }}
            >
              {
                <>
                  {location?.state?.from && (
                    <div
                      style={{
                        marginTop: 0,
                        marginBottom: 30,
                      }}
                    >
                      <TopNavigation jobData={jobData} loading={loadingJob} />
                    </div>
                  )}
                  <Row gutter={22} className="headerSection">
                    <Col span={22}>
                      <PersonalInfo
                        match={matchData}
                        showActions={true}
                        candidate={candidateData}
                        showSocial={true}
                        showVideo={true}
                        loading={loading}
                        jobData={jobData}
                      />
                      {/* <Summary
                      match={matchData}
                      candidate={candidateData}
                      Visible={true}
                      showBenchmark={true}
                    /> */}
                    </Col>
                  </Row>
                  <Row
                    style={{ marginTop: 25 }}
                    gutter={22}
                    className={
                      Motivation == false || !Background ? "align_Content" : ""
                    }
                  >
                    {/* Motivation */}
                    {(candidateData?.targetLocations?.length == 0 &&
                      !candidateData?.personalInformation &&
                      !candidateData?.yearlyCompensation?.currency &&
                      candidateData?.inspiringCompanies?.length == 0 &&
                      candidateData?.professionalInterests?.length == 0 &&
                      candidateData?.objectives?.length == 0 &&
                      candidateData?.sectors?.length == 0) == false && (
                      <Col span={Background == false ? 10 : 7}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 10,
                            fontSize: 20,
                            fontWeight: 600,
                            color: "white",
                            backgroundColor: "#2e4a79",
                            borderRadius: 10,
                            marginBottom: 30,
                          }}
                        >
                          <span>MOTIVATIONS</span>
                        </div>

                        <div ref={tfRef}>
                          <TargetLocations candidate={candidateData} />
                        </div>

                        <div
                          ref={professionalInterestsRef}
                          style={{ marginTop: 20 }}
                        >
                          <ProfessionalIntrests candidate={candidateData} />
                        </div>
                        <div
                          ref={professionalInterestsRef}
                          style={{ marginTop: 20 }}
                        >
                          <Myobjective candidate={candidateData} />
                        </div>
                        <div
                          ref={professionalInterestsRef}
                          style={{ marginTop: 20 }}
                        >
                          <Mytarget candidate={candidateData} />
                        </div>
                        <div
                          ref={professionalInterestsRef}
                          style={{ marginTop: 20 }}
                        >
                          <Inspirecompanies candidate={candidateData} />
                        </div>

                        <div ref={ycRef} style={{ marginTop: 20 }}>
                          {(matchData?.showExpectedCompensation == true ||
                            matchData?.showExpectedCompensation == null) && (
                            <YearlyCompensation
                              nocomments={location?.state?.comments}
                              candidate={candidateData}
                              match={matchData}
                            />
                          )}
                        </div>

                        {!match?.hidePersonalDetails && (
                          <div ref={pdRef} style={{ marginTop: 20 }}>
                            <PersonalDetails candidate={candidateData} />
                          </div>
                        )}
                      </Col>
                    )}
                    {/* Motivation */}

                    {/* Background */}
                    {/* Background */}
                    {
                      // (candidateData?.experiences?.length == 0 &&
                      //   candidateData?.education?.length == 0 &&
                      //   candidateData?.languages?.length == 0 &&
                      //   !candidateData?.resume &&
                      //   !candidateData?.coverLetter) == false
                      (candidateData?.experiences?.length > 0 ||
                        candidateData?.education?.length > 0 ||
                        candidateData?.languages?.length > 0 ||
                        candidateData?.resume ||
                        candidateData?.coverLetter) && (
                        <Col span={Motivation == false ? 10 : 9}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: 10,
                              fontSize: 20,
                              fontWeight: 600,
                              color: "#FFFFFF",
                              backgroundColor: "#BC9623",
                              borderRadius: 10,
                              marginBottom: 30,
                            }}
                          >
                            <span>BACKGROUND</span>
                          </div>

                          {/* Experience */}
                          <div style={{ marginTop: 20 }}>
                            <Experience
                              candidate={candidateData}
                              nocomments={location?.state?.comments}
                            />
                          </div>
                          {/* Education */}
                          <div style={{ marginTop: 20 }}>
                            <Education
                              candidate={candidateData}
                              nocomments={location?.state?.comments}
                            />
                          </div>
                          {/* Languages */}
                          <div style={{ marginTop: 20 }}>
                            <Languages
                              candidate={candidateData}
                              nocomments={location?.state?.comments}
                            />
                          </div>
                          {/* Resume */}
                          <div style={{ marginTop: 20 }}>
                            <Resume candidate={candidateData} />
                          </div>
                          {/* Attachment */}
                          <div style={{ marginTop: 20 }}>
                            <Attachment candidate={candidateData} />
                          </div>
                          {/* <div
                      style={{
                        marginTop: 20,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Printbutton
                        matchId={location.state?.matchId}
                        candidateId={candidateId}
                      />
                    </div> */}
                        </Col>
                      )
                    }
                    {/* Background End */}

                    {/* Recruiter's Comment */}
                    <Col
                      span={Motivation == false || Background == false ? 10 : 8}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: 10,
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#FFFFFF",
                          backgroundColor: "#418394",
                          borderRadius: 10,
                          marginBottom: 30,
                        }}
                      >
                        <span>RECRUITERS’ COMMENTS</span>
                      </div>

                      <div style={{ marginTop: 20 }}>
                        <Comments match={match} candidate={candidateData} />
                      </div>
                      {!loading1 &&
                      !loadingJob &&
                      match?.AssessmentReport !== null &&
                      !(
                        match?.AssessmentReport?.hideExperience &&
                        match?.AssessmentReport?.hideTraits &&
                        match?.AssessmentReport?.hideDrivers
                      ) ? (
                        <div style={{ marginTop: 20 }}>
                          <AssessmentTable match={match} jobData={jobData} />
                        </div>
                      ) : null}
                      <div style={{ marginTop: 20 }}>
                        <NextSteps
                          candidate={candidateData}
                          match={matchData}
                        />
                      </div>
                    </Col>
                    {/* Recruiter's Comment End */}
                  </Row>
                </>
              }
            </div>
          </>
        ) : (
          <>
            <Route exact path={path}>
              <Redirect to="/companies" />
            </Route>
          </>
        )}
      </div>
    </>
  );
};

export default ViewCandidate;
